import { Lang } from '../model/lang';
declare var firebase: any;

export class Constants {
	public static USER_LANGUAGE = 'en';

	public static APP_TEXTS_TABLE = 'app_texts';
	public static APP_TEXTS_JSON_TABLE = 'app_texts_json';
	public static CENTER_TABLE = 'centers';
	public static DIARY_TABLE = 'diary';
	public static DOCTOR_TABLE = 'doctors';
	public static EXTERNAL_USER_TABLE = 'external_users';
	public static INTERNAL_USER_TABLE = 'internal_users';
	public static LANGUAGE_TABLE = 'languages';
	public static MEDICINES_TABLE = 'medicines';
	public static MEDICINE_CATEGORIES_TABLE = 'medicine_categories';
	public static PARTICIPANTS_TABLE = 'participants';
	public static QUESTIONS_TABLE = 'questions';
	public static RESOURCE_TABLE = 'resources';
	public static SYMPTOM_CATEGORIES_TABLE = 'symptom_categories';
	public static SYMPTOMS_TABLE = 'symptoms';
	public static TRIALS_TABLE = 'trials';
	public static TRIAL_CENTERS_TABLE = 'trial_centers';
	public static TRIAL_DOCTORS_TABLE = 'trial_doctors';
	public static TRIAL_EXTERNALS_TABLE = 'trial_externals';
	public static TRIAL_INTERNAL_TABLE = 'trial_internals';
	public static USERS_TABLE = 'users';

	public static TranslationTypes = {
		comercialVariants: 'comercial_variant',
		medicines: 'medicines',
		medicineCategories: 'medicine_category',
		questions: 'questions',
		resources: 'resources',
		symptoms: 'symptoms',
		symptomCategories: 'symptom_category',
		values: 'values',
	};

	public static USER_TYPE_ADMIN = 'admin';
	public static USER_TYPE_DOCTOR = 'doctor';
	public static USER_TYPE_EXTERNAL_USER = 'external_user';
	public static USER_TYPE_INTERNAL_USER = 'internal_user';

	private static _firebaseEmulatorEnabled = false;
	private static _apiLocalEnabled = false;
	public static REPOSITORY_WITH_FIREBASE = true;

	public static REPOSITORY_AUTH_TOKEN = 'autToken';
	public static REPOSITORY_AUTH_UID = 'authUid';
	public static REPOSITORY_AUTH_EMAIL = 'authEmail';
	public static baseUrl = Constants._apiLocalEnabled ? 'http://localhost:8081' : 'https://app.itk-diary.com';

	private static _firestore = null;
	public static firestore(): any {
		if (!this.REPOSITORY_WITH_FIREBASE) return null;
		if (this._firestore == null) {
			this._firestore = firebase.firestore();
			// Disable deprecated features
			this._firestore.settings({
				timestampsInSnapshots: true,
			});
			if (this._firebaseEmulatorEnabled) {
				console.log('Using Firestore emulator');
				this._firestore.useEmulator('127.0.0.1', 8080);
			} else {
				console.log('Using Firestore production');
			}
		}
		return this._firestore;
	}

	private static _auth = null;
	public static auth(): any {
		if (!this.REPOSITORY_WITH_FIREBASE) return null;
		if (this._auth == null) {
			this._auth = firebase.auth();
			if (this._firebaseEmulatorEnabled) {
				console.log('Using Auth emulator');
				this._auth.useEmulator('http://127.0.0.1:9099');
			} else {
				console.log('Using Auth production');
			}
		}
		return this._auth;
	}

	//this.functions.httpsCallable("createDoctor");
	//this.functions = firebase.app().functions("europe-west1");
	private static _functions = null;
	public static functions(): any {
		if (!this.REPOSITORY_WITH_FIREBASE) return null;
		if (this._functions == null) {
			this._functions = firebase.app().functions('europe-west1');
			if (this._firebaseEmulatorEnabled) {
				console.log('Using Functions emulator');
				this._functions.useEmulator('127.0.0.1', 5001);
			} else {
				console.log('Using Functions production');
			}
		}
		return this._functions;
	}

	public static VALUE_PREDEFINED_GROUPS = [
		{
			text: 'Respuestas NO/SI',
			options: [
				{
					text: 'NO',
					value: 0,
					langs: [
						{
							language: 'en',
							text: 'NO',
						},
					],
				},
				{
					text: 'SÍ',
					value: 1,
					langs: [
						{
							language: 'en',
							text: 'YES',
						},
					],
				},
			],
		},
		{
			text: 'Respuestas dosis de 0 a <=3',
			options: [
				{
					text: '0',
					value: 0,
					langs: [
						{
							language: 'en',
							text: '0',
						},
					],
				},
				{
					text: '1',
					value: 1,
					langs: [
						{
							language: 'en',
							text: '1',
						},
					],
				},
				{
					text: '2',
					value: 2,
					langs: [
						{
							language: 'en',
							text: '2',
						},
					],
				},
				{
					text: '<=3',
					value: 3,
					langs: [
						{
							language: 'en',
							text: '<=3',
						},
					],
				},
			],
		},
		{
			text: 'Respuestas síntomas (0 a 3)',
			options: [
				{
					text: 'Ausencia de síntomas',
					value: 0,
					langs: [
						{
							language: 'en',
							text: 'No symptoms',
						},
					],
				},
				{
					text: 'Síntomas leves',
					value: 1,
					langs: [
						{
							language: 'en',
							text: 'Mild symptoms',
						},
					],
				},
				{
					text: 'Síntomas moderados',
					value: 2,
					langs: [
						{
							language: 'en',
							text: 'Moderate symptoms',
						},
					],
				},
				{
					text: 'Síntomas graves',
					value: 3,
					langs: [
						{
							language: 'en',
							text: 'Severe symptoms',
						},
					],
				},
			],
		},
	];

	public static VALUE_PREDEFINED_LANGUAGES: Lang[] = [
		{
			language: 'en',
			text: null,
		},
	];

	public static validateLang(langs: Lang[]): boolean {
		if (langs == null) return false;

		var valid = true;
		langs.forEach((lang) => {
			if (lang.language == null || lang.language == '' || lang.text == null || lang.text == '') valid = false;
		});

		return valid;
	}

	public static getForLanguage(langs: Lang[], language: string): string {
		if (langs == null) return '';

		var correctText = '';
		langs.forEach((lang) => {
			if (lang.language == language) correctText = lang.text;
		});

		return correctText;
	}
}

export function accentFold(inStr) {
	return inStr.replace(
		/([àáâãäå])|([ç])|([èéêë])|([ìíîï])|([ñ])|([òóôõöø])|([ß])|([ùúûü])|([ÿ])|([æ])/g,
		function (str, a, c, e, i, n, o, s, u, y, ae) {
			if (a) return 'a';
			else if (c) return 'c';
			else if (e) return 'e';
			else if (i) return 'i';
			else if (n) return 'n';
			else if (o) return 'o';
			else if (s) return 's';
			else if (u) return 'u';
			else if (y) return 'y';
			else if (ae) return 'ae';
		}
	);
}
